import React from 'react';
import { Link } from 'react-router-dom';
import './AboutMe.css';

const AboutMe = function(props) {
  const { pathname } = props || {};
  return (
    <div className='section-container'>
      {(pathname === '/about')
        ? <h2>About Me</h2>
        : <h3>About Me</h3>
      }
      <div id="about" className ="detail-container">

        <p className='indent'>I am from Jacksonville, Florida originally. I was a Boy Scout growing up, and I earned the rank of <span className='emphasize'>Eagle Scout</span>. 
        I graduated college from Johnson & Wales University in 2019. I completed Associates' degrees in Culinary Arts
        and Baking and Pastry. I also received a <span className='emphasize'>Bachelor's in Entrepreneurship</span>. This has led me to work with
        several restaurants and breweries in the Charlotte area. I began to teach myself coding 
        in 2020, while working for a small company called Freshlist. I made a lot of use of freeCodeCamp and LinkedIn Learn videos. Aside from coding I enjoy,
        cooking and baking, watching Netflix documentaries and anime, playing video games, and spending time with my family.  
        </p>

        <p className='indent'>Working with Freshlist, I was able to use HTML and CSS to edit our Shopify order slips to include or logo, format, and style. 
        We were able to prototype an <span className='emphasize'>Instagram follower counter</span>. The prototype was an HTML scrape coded with Python to output 
        the follower count to a seven-segment display that was wired to a Raspberry Pi. I have also built a full-stack web app allowing users to search through 
        Freshlist's recipes. We have also set up an Express API with PostgreSQL database for a proprietary app to help facilitate business between Freshlist and 
        local farmers</p>

        <p className='indent'>My first job as a Software Developer has been with <span className='emphasize'>GoTab</span>. While working with them, I have had
        the opportunity to learn and apply my skills. With guidance, I've written software integrations for <span className='emphasize'>3</span> major Restaurant 
        POS/KDS systems. I've also worked to interface our web app with a <span className='emphasize'>Thermal Receipt Printer</span> over 
        <span className='emphasize'> USB</span>. GoTab's database is large and complex, which has greatly improved my skills and understanding of 
        <span className='emphasize'> SQL and PLPGSQL</span>. {/* Insert something here about shell scripts. Maybe a new paragraph?*/}</p>

        <div className='interest-list center'>
          <p className='indent'>In the past year I have: </p>
          <ul className='center'>
            <li>Earned <span className='emphasize'>AWS Cloud Practitioner</span> Certification</li>
            <li>Built a <span className='emphasize'>Robot Continuum Arm</span> to play with my cats</li>
            <li>Built Automated Cat Feeders with <span className='emphasize'>3D printed parts</span></li>
            <li>Started building a <span className='emphasize'>bin</span> folder with custom commands and shortcuts</li>
            <li>Written an <span className='emphasize'>Apple LaunchCtl</span> script to automate pulling a new database from production</li>
          </ul>
        </div>

        <p className='indent'>I am proficient in <span className='emphasize'>Node.js, React, Express, PostrgreSQL, and Bash and Z shell</span>. On the side I am teaching myself 
        <span className='emphasize'> C/C++ and Python</span> to be able to interface with various microcontrollers and embedded devices. I am always eager to learn 
        more.</p>

      </div>
      {(pathname === '/about')
        ? <button className="back" type="button"><Link to='/'>Back</Link></button>
        : null }
    </div>
    
    )
}

export default AboutMe;