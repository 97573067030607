import React from 'react';

export default React.createContext({
  projects: [],
  data: [],
  temperature: 0,
  humdity: 0,
  images: [],
  repo: {},
});
