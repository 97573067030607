import React from 'react';
import './Footer.css';

const Footer = function() {
  return (
    <footer>
      <p> &#169; 2022 RHammock</p>
    </footer>
  )
}

export default Footer;