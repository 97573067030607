import React from 'react';

class ArduinoProject extends React.Component {
    render() {
        const {
            pathname,
            image,
            alt_text,
            title,
            project_desc,
            server_repo,
            libraries,
        } = this.props;

        return (
            <div className="detail-container project-container">
            {/*  T0DO 
                Add arduino projects section
                Update projects list in app component to include arduino
                Upload arduino sketches to github through platformIO
                 */}
                 <h3>Arduino Based</h3>
                 <img src={image} alt={alt_text} className="projectImg" />
                {(pathname === '/projects')
                    ? <h3>{title}</h3>
                    : <h4>{title}</h4>
                }
                {server_repo == null 
                    ? <></>
                    : <div className='repo-container'>
                        <a rel='noreferrer' target='_blank' href={server_repo}>GitHub Repo</a> 
                    </div>
                }
                <p>{project_desc} </p>
                {libraries == null ? <></> : <p><strong>Libraries: </strong>{libraries}</p>}
            </div>

        )
    }
}

export default ArduinoProject;